<template>
  <div>
    <NavBarAdmin></NavBarAdmin>
    <FarmacyInventoryComponent></FarmacyInventoryComponent>
  </div>
</template>
<script>
import NavBarAdmin from "../../components/NavBarAdmin.vue";
import FarmacyInventoryComponent from "@/components/FarmacyInventory.vue";
export default {
  name: "FarmacyInventory",
  components: {
    NavBarAdmin,
    FarmacyInventoryComponent
  }
};
</script>
